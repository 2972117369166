import $axios from 'axios';

export const basicLoginApi = (params) => {
    return $axios({
        method: 'POST',
        url: "/login/basicLogin",
        data: params
    })
}

export const imgCodeIsShowApi = () => {
    return $axios({
        method: 'GET',
        url: "/login/basicLogin/imgCodeIsShow"
    })
}

export const findPriorityRelationApi = () => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'GET',
        url: "/relation/findPriorityRelation",
        headers: {
            Authorization: token,
        }
    })
}

