<template>
  <div style="background: #ececec; padding: 0px; text-align: center">
    <a-card title="" :bordered="false" class="container">
      <br /><br /><br />
      <br /><br /><br />
      <span style="color:red">{{loginMsg}}</span>
      <br /><br /><br />
      <a-input v-model:value="loginForm.basicLogin.loginName" placeholder="手机号 | 邮箱 | 用户名" :allowClear="true" style="width: 300px;" size="large" @pressEnter="login">
        <template #prefix>
          <UserOutlined />
        </template>
        <template #suffix>
          <a-tooltip title="必传">
            <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
      <br /><br /><br />
      <a-input-password v-model:value="loginForm.basicLogin.password" placeholder="密码" :allowClear="true" style="width: 300px;" size="large" @pressEnter="login">
        <template #prefix>
          <LockOutlined />
        </template>
        <template #suffix>
          <a-tooltip title="必传">
            <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input-password>
      <br /><br /><br />

      <div v-if="isShowImgCode">
        <a-image :src="imgCodeUrl" style="width: 200px;" @click="refreshImgCode" :preview="false"/>
        <a-input v-model:value="loginForm.basicLogin.imgCode" placeholder="验证码" style="width: 100px;" size="large" @pressEnter="login" />
        <br /><br /><br />
      </div>

      <a-button type="primary" size="large" @click="login">登录</a-button>
    </a-card>

    <a-layout-footer>
      <div style="text-align: center">
        Copyright © julycloud.cn
      </div>
    </a-layout-footer>
  </div>
</template>

<script>
// import {
//   UserOutlined,
//   InfoCircleOutlined,
//   LockOutlined,
//
// } from '@ant-design/icons-vue';

import * as LoginApi from '@/api/LoginApi.js';
import {message} from "ant-design-vue";

export default {
  name: 'Login',
  components: {
    // UserOutlined,
    // InfoCircleOutlined,
    // LockOutlined,
    
  },
  data() {
    return {
      loginForm: {
        basicLogin: {
          loginName: "",
          password: "",
          imgCode: ""
        }
      },
      loginMsg: '',
      isShowImgCode: false,
      imgCodeUrl: '',
      imgCodeUrlOld: '/api/login/basicLogin/validateCode',
      myTimer: '',
    }
  },

  methods: {
    login() {
      LoginApi['basicLoginApi'](this.loginForm.basicLogin)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        let data = result.data.data;
        if (code === 1) {
          sessionStorage.setItem('token', data.token);
          sessionStorage.setItem('expire', data.expire);
          sessionStorage.setItem('loginName', data.loginName);
          sessionStorage.setItem('realname', data.realname);
          sessionStorage.setItem('nickname', data.nickname);
          sessionStorage.setItem('avatar', data.avatar);
          this.findPriorityRelation();
          return;
        }
        this.loginMsg = "[" + code + "] --- " + msg;
        if (code === 1006) {
          this.isShowImgCode = true;
          this.refreshImgCode();
          if (data !== null) {
            this.closeImgCodeTask(data["expire"]);
          }
        }

      })
      .catch((e) => {
        message.error(e.message);
      })

    },

    refreshImgCode() {
      this.imgCodeUrl = this.imgCodeUrlOld + "?t=" + Math.random();
    },

    imgCodeIsShow() {
      LoginApi['imgCodeIsShowApi']()
      .then((result) => {
        let code = result.data.code;
        let data = result.data.data;
        if (code === 1 && data["imgCodeIsShow"] === "1") {
          this.isShowImgCode = true;
          this.refreshImgCode();
          this.closeImgCodeTask(data["expire"]);
          return;
        }
        this.isShowImgCode = false;
      })
      .catch((e) => {
        message.error(e.message);
      })
    },

    closeImgCodeTask(obj) {
      this.myTimer = setTimeout(() => this.isShowImgCode = false, obj);
    },

    findPriorityRelation() {
      LoginApi['findPriorityRelationApi']()
      .then((result) => {
        let code = result.data.code;
        let data = result.data.data;
        if (code === 1) {
          sessionStorage.setItem('companyId', data['companyId']);
          sessionStorage.setItem('roleId', data['roleId']);
          // this.$router.push("/usercenter");
          // 重定向，解决keep-alive缓存问题，浏览器会执行一系列缓存清除操作，以确保用户获取最新的页面内容。
          window.location.href = "/usercenter";
        }
      })
      .catch((e) => {
        message.error(e.message);
      })
    },

  },

  created() {
    this.imgCodeIsShow();
  },

  mounted() {

  }

}
</script>

<style>
  .container {
    width: 100%;
    min-height: 100vh;
    /*background-image: url('../../assets/logo/bg_login.png');*/
    /*background-size: cover;*/
    /*background-position: center;*/
  }
</style>

